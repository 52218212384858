* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  background-color: rgba(158, 207, 250, 0.2);
}

html {
  font-size: 90%;
  background-color: rgba(158, 207, 250, 0.2);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  /* width: fit-content; */
}

body::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}

.MuiTooltip-tooltip{ 
  background-color: white !important;
   color: grey !important;
   border: 1px solid black !important;
}

.MuiTooltip-arrow {
  /* color: white !important; */
  /* border: 1px solid black !important; */
}
